<template>
  <div>
    <h1>Reservations</h1>
    <ul>
      <li v-for="project in projects" :key="project.id">
        <p>{{ project.name }} scheduled for {{ project.plannedStartDate }}</p>
        <div v-for="volunteer in project.volunteers" :key="volunteer.emailAddress">
          {{ volunteer.reservationDate }}
          {{ volunteer.firstName }} {{ volunteer.lastName }} {{ volunteer.emailAddress }}
          is confirmed: {{ volunteer.confirmedParticipant ? true : false }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    projects: []
  }),

  beforeMount () {
    this.loadProjects()
  },

  methods: {
    loadProjects () {
      fetch('/api/v1/community/projects/with-reservations', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          this.projects = data
        })
    }
  }
}
</script>
